











import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class CellRendererLink extends Vue {
    params: any = null;

    get url() {
        return {name: 'orderDetail', params: {id: this.params.data.id}};
    }

    get isOrderContract(){
        let data = this.params.data;

        return data.fromOrderCode && data.type === 3;
    }
}
