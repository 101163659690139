var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{staticClass:"flex items-center  mb-3",attrs:{"to":_vm.typeValue === _vm.receiptType.Payment ? '/payment_vouchers' : '/receipt_vouchers'}},[_c('feather-icon',{staticClass:"mr-2 text-xl text-black font-medium",attrs:{"icon":"ArrowLeftIcon","svgClasses":"w-6 h-6"}}),_c('span',{staticClass:"text-xl text-black font-medium"},[_vm._v(_vm._s(_vm.typeValue === _vm.receiptType.Payment ? 'Danh sách phiếu chi' : 'Danh sách phiếu thu'))])],1),_c('vx-card',{attrs:{"title":_vm.isUpdate ? _vm.receipt.code : 'Thêm mới ' + _vm.receiptTitle}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-1/2"},[_c('div',{staticClass:"vx-row flex items-center"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('ReceiptCode'))+" ")])]),_c('div',{staticClass:"vx-col w-2/3"},[_c('vs-input',{staticClass:"w-full",attrs:{"disabled":_vm.isUpdate && _vm.readOnly,"placeholder":"Mã phiếu sẽ được sinh tự động "},model:{value:(_vm.receipt.code),callback:function ($$v) {_vm.$set(_vm.receipt, "code", $$v)},expression:"receipt.code"}})],1)])]),_c('div',{staticClass:"vx-col w-1/2"},[_c('div',{staticClass:"vx-row flex items-center"},[_c('div',{staticClass:"vx-col w-1/3 "},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.type === _vm.receiptType.Payment ? _vm.$t('PaymentVoucher.customerName') : _vm.$t('ReceiveVoucher.customerName'))+" ")])]),_c('div',{staticClass:"vx-col w-2/3"},[(!_vm.isUpdate)?_c('CustomerSuggestion',{ref:"customerSuggestion",attrs:{"allowAddWhenNotExists":true,"isAddFull":true,"isRedirect":false,"listSelected":_vm.listCustomerSelected,"placeholder":_vm.typeValue === _vm.receiptType.Payment ? 'Chọn nhà cung cấp' : 'Chọn khách hàng'},on:{"select":_vm.onCustomerSelected,"selectedItemRemoved":_vm.onCustomerSelectedRemove}}):_vm._e(),(_vm.isUpdate)?_c('vs-input',{staticClass:"w-full",attrs:{"disabled":"true"},model:{value:(_vm.receipt.customerName),callback:function ($$v) {_vm.$set(_vm.receipt, "customerName", $$v)},expression:"receipt.customerName"}}):_vm._e()],1)])])]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('ReceiptGroupName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row flex items-center"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('ReceiptGroupName'))+" "),_c('span',{staticClass:"color-red"},[_vm._v("*")])])]),_c('div',{staticClass:"vx-col w-2/3"},[(!_vm.isUpdate)?_c('receipt-group-suggestion',{attrs:{"placeholder":_vm.$t('ReceiptGroupName'),"receiptType":_vm.type,"allow-add-when-not-exists":false,"is-multiple":false,"show-add-button":true,"listSelected":_vm.listReceiptGroupSelected},on:{"select":_vm.selectReceiptGroup}}):_vm._e(),(_vm.isUpdate)?_c('vs-input',{staticClass:"w-full",attrs:{"disabled":"true"},model:{value:(_vm.receipt.receiptGroupName),callback:function ($$v) {_vm.$set(_vm.receipt, "receiptGroupName", $$v)},expression:"receipt.receiptGroupName"}}):_vm._e()],1)])]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.type === _vm.receiptType.Payment ? _vm.$t('PaymentVoucher.receiptFullName') : _vm.$t('ReceiveVoucher.receiptFullName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row flex items-center"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(_vm._s(_vm.type === _vm.receiptType.Payment ? _vm.$t('PaymentVoucher.receiptFullName') : _vm.$t('ReceiveVoucher.receiptFullName'))+" "),_c('span',{staticClass:"color-red"},[_vm._v("*")])])]),_c('div',{staticClass:"vx-col w-2/3"},[(!_vm.isUpdate)?_c('receipt-user-suggestion',{attrs:{"placeholder":_vm.type === _vm.receiptType.Payment ? 'Gõ tên người nhận' : 'Gõ tên người nộp',"value":_vm.receipt.receiptFullName,"readonly":_vm.readOnly},on:{"getModel":_vm.getReceiptUserName,"getValue":_vm.getUser},model:{value:(_vm.receipt.receiptFullName),callback:function ($$v) {_vm.$set(_vm.receipt, "receiptFullName", $$v)},expression:"receipt.receiptFullName"}}):_vm._e(),(_vm.isUpdate)?_c('vs-input',{staticClass:"w-full",attrs:{"disabled":"true"},model:{value:(_vm.receipt.receiptFullName),callback:function ($$v) {_vm.$set(_vm.receipt, "receiptFullName", $$v)},expression:"receipt.receiptFullName"}}):_vm._e()],1)])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('DateRecording'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row flex items-center"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('DateRecording'))+" "),_c('span',{staticClass:"color-red"},[_vm._v("*")])])]),_c('div',{staticClass:"vx-col w-2/3"},[_c('div',{staticClass:"w-full"},[_c('vx-input-group',[_c('flat-pickr',{ref:"datePicker",staticClass:"w-full",attrs:{"config":_vm.configDateTimePicker,"placeholder":"Ngày ghi nhận","disabled":_vm.isUpdate},model:{value:(_vm.receipt.dateRecording),callback:function ($$v) {_vm.$set(_vm.receipt, "dateRecording", $$v)},expression:"receipt.dateRecording"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text bg-primary"},[_c('vs-button',{staticClass:"w-10 h-36",attrs:{"color":"primary","icon-pack":"feather","icon":"icon-calendar"},on:{"click":_vm.selectDate}})],1)])],2),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])])]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Phonenumber'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row flex items-center"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('Phonenumber'))+" "),_c('span',{staticClass:"color-red"},[_vm._v("*")])])]),_c('div',{staticClass:"vx-col w-2/3"},[_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":_vm.$t('Phonenumber'),"autocomplete":"off","name":"required","disabled":_vm.isUpdate},model:{value:(_vm.receipt.receiptPhoneNumber),callback:function ($$v) {_vm.$set(_vm.receipt, "receiptPhoneNumber", $$v)},expression:"receipt.receiptPhoneNumber"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-1/2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Amount'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row flex items-center"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('Amount'))+" "),_c('span',{staticClass:"color-red"},[_vm._v("*")])])]),_c('div',{staticClass:"vx-col w-2/3"},[_c('vx-input-group',[_c('money',_vm._b({ref:"moneyAmount",staticClass:"vs-inputx vs-input--input normal text-right",attrs:{"disabled":_vm.isUpdate},model:{value:(_vm.receipt.amount),callback:function ($$v) {_vm.$set(_vm.receipt, "amount", $$v)},expression:"receipt.amount"}},'money',_vm.moneyOption,false)),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text bg-primary"},[_c('span',[_vm._v("VNĐ")])])]),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],2)],1)])]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-1/2"},[_c('div',{staticClass:"vx-row flex"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('Note'))+" ")])]),_c('div',{staticClass:"vx-col w-2/3"},[_c('vs-textarea',{staticClass:"mb-0",attrs:{"rows":"1","placeholder":_vm.$t('Note'),"disabled":_vm.readOnly && _vm.isUpdate},model:{value:(_vm.receipt.note),callback:function ($$v) {_vm.$set(_vm.receipt, "note", $$v)},expression:"receipt.note"}})],1)])])]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Phương thức thanh toán","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row flex"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('label',{staticClass:"vs-input--label text-lg",attrs:{"for":""}},[_vm._v(" Phương thức thanh toán "),_c('span',{staticClass:"color-red"},[_vm._v("*")])])]),_c('div',{staticClass:"vx-col w-2/3"},[_c('v-select',{attrs:{"placeholder":"Chọn hình thức thanh toán","multiple":false,"label":"name","disabled":_vm.isUpdate,"options":_vm.paymentMethods},model:{value:(_vm.paymentMethodsSelected),callback:function ($$v) {_vm.paymentMethodsSelected=$$v},expression:"paymentMethodsSelected"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)})],1),(_vm.referenceType !== _vm.codeReferenceType.Order
                                                               && _vm.referenceType !== _vm.codeReferenceType.OrderReturned)?_c('div',{staticClass:"vx-col w-1/2"},[_c('div',{staticClass:"vx-row flex"},[_c('div',{staticClass:"vx-col w-1/3"},[_c('span',[_vm._v("Mã tham chiếu:")])]),_c('div',{staticClass:"vx-col w-2/3"},[_c('vs-input',{staticClass:"w-full",attrs:{"disabled":_vm.isUpdate && _vm.readOnly,"placeholder":"Mã tham chiếu"},model:{value:(_vm.receipt.codeReference),callback:function ($$v) {_vm.$set(_vm.receipt, "codeReference", $$v)},expression:"receipt.codeReference"}})],1)])]):_vm._e()]),(_vm.referenceType === _vm.codeReferenceType.Order
                                               || _vm.referenceType === _vm.codeReferenceType.OrderReturned)?_c('div',{staticClass:"vs-row mb-2"},[_c('ReceiptCertificateComponent',{ref:"receiptCertificate",attrs:{"reference-type":_vm.referenceType,"customer-id":_vm.receipt.customerId,"amount":_vm.receipt.amount,"readOnly":_vm.isUpdate},on:{"getValue":_vm.getReceiptCertificate,"setCustomerInfo":_vm.setCustomerInfo}})],1):_vm._e(),_c('div',{staticClass:"vs-row mb-2 text-right"},[_c('h4',{staticClass:"italic"},[_vm._v(" Tổng số tiền thanh toán: "+_vm._s(_vm.formatNumber(_vm.receipt.amount))+" đ")])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full text-right"},[_c('button',{attrs:{"type":"submit","hidden":"true"}}),_c('vs-button',{staticClass:"mb-2",attrs:{"type":"border","color":"warning"},on:{"click":_vm.deleteReceipt}},[_vm._v(" Hủy ")]),(!_vm.readOnly)?_c('vs-button',{ref:"loadableButton",staticClass:"vs-con-loading ml-3",attrs:{"color":"primary","id":"btnSaveReceipt","disabled":_vm.saving},on:{"click":_vm.save}},[_vm._v(" Lưu ")]):_vm._e()],1)])])]}}])}),_c('OrderPayment',{ref:"orderPaymentRef",attrs:{"money":_vm.receipt.amount,"paidMoney":_vm.receipt.amount,"data":_vm.receipt.receiptPayments},on:{"saveSuccessed":_vm.onPaymentMethodSaveSuccessed}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }