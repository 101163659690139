import {CodeReferenceType, ReceiptType} from "@/constants/receipt";
import {PaymentMethod} from "@/constants/Order";

export class Receipt {
    code: string;
    codeReference: string;
    customerId: string;
    customerName: string;
    receiptFullName: string;
    receiptPhoneNumber: string;
    receiptGroupId: string;
    receiptGroupName: string;
    amount: number;
    dateRecording: any;
    isCalculateAutomatic: boolean;
    type: ReceiptType;
    note: string;
    branchId: string;
    receiptPayments: ReceiptPayment[];
    receiptCertificates: ReceiptCertificate[];

    constructor(code: string = '', codeReference: string = '', customerId: string = '',
                receiptFullName: string = '', receiptPhoneNumber: string = '', receiptGroupId: string = '',
                amount: number = 0, dateRecording: Date = new Date(), isCalculateAutomatic: boolean = true,
                type: ReceiptType = ReceiptType.Payment, note: string = '', branchId: string = '', isAccounting: boolean = true) {
        this.code = code;
        this.codeReference = codeReference;
        this.customerId = customerId;
        this.receiptFullName = receiptFullName;
        this.receiptPhoneNumber = receiptPhoneNumber;
        this.receiptGroupId = receiptGroupId;
        this.amount = amount;
        this.dateRecording = new Date();
        this.isCalculateAutomatic = isCalculateAutomatic;
        this.type = type;
        this.note = note;
        this.branchId = branchId;
        this.receiptPayments = [];
        this.receiptCertificates = [];
    }
}

export class ReceiptCertificate {
    certificateId: string;
    certificateCode: string;
    referenceType: CodeReferenceType | null;
    amount: number;

    constructor(certificateId: string, certificateCode: string, referenceType: CodeReferenceType, amount: number) {
        this.certificateId = certificateId;
        this.certificateCode = certificateCode;
        this.referenceType = referenceType;
        this.amount = amount;
    }
}

export class ReceiptPayment {
    id: number;
    name: string;
    paymentMethod: PaymentMethod;
    referenceCode: string;
    amount: number;
    money: number;

    constructor(paymentMethod: PaymentMethod = PaymentMethod.Transfer, referenceCode: string = '', amount: number = 0) {
        this.paymentMethod = paymentMethod;
        this.referenceCode = referenceCode;
        this.amount = amount;
    }
}