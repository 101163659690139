




import {Component, Vue} from "vue-property-decorator";
import ReceiptForm from "@/views/cash-book/receipt/receipt-form.vue";

@Component({
    components: {
        ReceiptForm
    }
})
export default class PaymentVoucherDetail extends Vue {
}

