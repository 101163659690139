
























import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {ReceiptType} from "@/constants/receipt";
import {SuggestionModel, SuggestionType} from '@/components/suggestion/base-suggestion/SuggestionModel';
import {ReceiptService} from "@/views/cash-book/receipt/receipt.service";
import {ReceiptGroupSuggestionModel} from "@/models/cash-book/receipt/ReceiptGroupSuggestionModel";
import Suggestion from "@/components/suggestion/base-suggestion/Suggestion.vue";

@Component({
    components: {
        Suggestion
    }
})

export default class ReceiptGroupSuggestion extends Vue {
    @Prop({default: SuggestionType.input}) type!: SuggestionType;
    @Prop({default: ""}) placeholder!: string; // popup | input
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: false}) showAddButton!: boolean;
    @Prop({default: false}) allowAddWhenNotExists!: boolean;
    @Prop({default: () => null}) value!: ReceiptGroupSuggestionModel;
    @Prop({default: () => []}) listSelected!: SuggestionModel[];
    @Prop({default: ReceiptType.Payment}) receiptType!: ReceiptType;
    @Prop({default: () => []}) data: SuggestionModel[];
    @Ref('suggestionComponent') suggestionComponent!: any;

    searchTerm = "";
    listSuggestion: SuggestionModel[] = [];
    isLoading = false;
    private receiptService = new ReceiptService();

    @Watch('data')
    getData(values: SuggestionModel[]) {
        this.listSuggestion = values;
    }

    get notFoundText() {
        return this.$t("noReceiptGroupFounded", [this.searchTerm]);
    }

    get addLabel() {
        const add = this.$t('Add');
        const customer = this.$t('Customer');
        return `${add} ${customer}`;
    }

    mounted() {
    }

    onSearch(searchTerm: string) {
        this.searchTerm = searchTerm;
        this.search();
    }

    onShown() {
        if (this.listSuggestion.length === 0) {
            this.search();
        }
    }

    show() {
        this.suggestionComponent.show();
    }

    private async search() {
        this.isLoading = true;
        try {
            const result = await this.receiptService.suggestionGroup(
                this.searchTerm, this.receiptType
            );
            this.isLoading = false;
            this.listSuggestion = result.items.map(
                (receiptGroupSuggestion: ReceiptGroupSuggestionModel) => {
                    return new SuggestionModel(
                        receiptGroupSuggestion.id,
                        receiptGroupSuggestion.name,
                        undefined,
                        undefined,
                        this.checkIsSelected(receiptGroupSuggestion.id),
                        receiptGroupSuggestion
                    )
                }
            );
        } catch (error) {
            this.listSuggestion = [];
        }
    }

    private checkIsSelected(id: string): boolean {
        const index = this.listSelected.findIndex(
            (item: SuggestionModel) => {
                return item.id === id;
            }
        );
        return index !== -1;
    }
}
