





































































import {Component, Vue, Prop, Watch, Ref} from "vue-property-decorator";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {AgGridVue} from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select'
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";
import SettingColumns from "@/components/SettingColumns.vue";
import {SettingColumnService} from "@/services/SettingColumnService";
import {ActionResult} from "@/models/ActionResult";
import {QueryLoadOption, SortingInfo} from "@/models/QueryLoadOptions";
import filter from 'lodash/filter';
import map from 'lodash/map';
import each from 'lodash/each';
import sumBy from 'lodash/sumBy';
import {OrderService} from '@/views/order/OrderService';
import {CodeReferenceType} from "@/constants/receipt";
import {LoadResult} from "@/models/LoadResult";
import {OrderType} from "@/constants/Order";
import moment from "moment";
import {formatNumber} from "@/common";
import CellRendererLink from "@/views/order/order-list/cell-renderer/CellRendererLink.vue";
import {Common} from '@/helper/common';
import debounce from 'lodash/debounce';
import NumbericCellEditor from "@/views/order/order-edit/cell-editor/NumbericCellEditor.vue";
import CustomerCellComponent from "@/components/ag-grid/CustomerCellComponent.vue";
import {ReceiptCertificateResult} from "@/models/cash-book/receipt/ReceiptCertificateResult";

@Component({
    components: {
        VuePerfectScrollbar,
        AgGridVue,
        vSelect,
        SettingColumns,
    }
})

export default class ReceiptCertificateComponent extends Vue {
    @Ref('settingColumnsRef') settingColumnsRef!: any;
    @Prop({default: () => false}) readOnly: boolean;
    @Prop({default: () => true}) isCalculateAutomatic: boolean;
    @Prop({default: () => ''}) customerId: string;
    @Prop({default: () => 0}) amount: number;
    @Prop({default: () => CodeReferenceType.Order}) referenceType: CodeReferenceType;

    keyword: string = '';
    gridColumnApi: any = null;
    gridApi: any = null;
    gridOptions: any = null;
    columnDefs: any = null;
    defaultColDef: any = null;
    pinnedTopRowData: any = [];
    cellRenderComponents: any = {
        CellRendererLink,
        NumbericCellEditor,
        CustomerCellComponent
    };

    settings: any = {
        maxScrollbarLength: 60,
        wheelSpeed: 0.70,
    };
    settingColumnConfig: SettingColumnResult = new SettingColumnResult('OrderCertificate');
    defaultSettingColumns: any[] = [];
    isAutomatic: boolean = true;
    settingColumnService = new SettingColumnService();
    debounce: any = null;
    //Search ReceiptCertificate
    customerIdValue: string;
    referenceTypeValue: CodeReferenceType;
    amountValue: number = 0;
    loadOptions: QueryLoadOption = new QueryLoadOption();
    filterQuery: string;
    selectFieldRequired: string[] = ['id', 'customerId', 'type', 'fromOrderCode', 'customerName', 'phone'];
    orderService = new OrderService();
    receiptsCertificates: ReceiptCertificateResult[] = [];
    common = new Common();

    constructor() {
        super();
        this.loadOptions.skip = 0;
        this.loadOptions.take = 1000;
        this.loadOptions.sort = [new SortingInfo('createTime', false)];
    }

    beforeMount() {
        this.gridOptions = {
            localeText: {noRowsToShow: 'Không có đơn hàng nào cần thanh toán'},
        };

        this.defaultSettingColumns = this.getDefaultColumnSetting();
        this.columnDefs = this.getColumnDefaultApi();
        this.initial();
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
    }

    @Watch('isCalculateAutomatic')
    getIsAutomatic(value: boolean) {
        this.isAutomatic = value;
    }

    changeIsAutomatic() {
        this.getAutomaticRemainPrice();
    }

    @Watch('customerId')
    changeCustomerId(value: string) {
        if (value && value !== '' && !this.readOnly) {
            this.customerIdValue = value;
            this.search();
        }
    }

    @Watch('referenceType')
    changeReferenceType(value: CodeReferenceType) {
        this.referenceTypeValue = value;
    }

    // Tính toán tự động cho số tiền phải trả
    @Watch('amount')
    getAmount(value: number) {
        this.amountValue = value;
        this.getAutomaticRemainPrice();
    }

    get rowData() {
        return this.receiptsCertificates;
    }

    initial() {
        this.settingColumnService.getByModuleName('OrderCertificate')
            .then((result: ActionResult<SettingColumnResult>) => {
                this.settingColumnConfig = result.data as SettingColumnResult;
                if (!this.settingColumnConfig) {
                    this.settingColumnConfig = new SettingColumnResult('OrderCertificate');
                    this.settingColumnConfig.columnConfigData = this.defaultSettingColumns;
                }

                this.getFieldSelectFromResult();
                if (this.settingColumnsRef) {
                    this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);
                }

                if (this.gridApi) {
                    this.gridApi.refreshCells();
                }
            });
    }

    searchByCode(value: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = debounce(() => {
            if (this.keyword !== '' && this.keyword !== null && this.keyword.trim() !== '' && this.keyword.trim() !== null) {
                this.renderFilterQuery();
                this.search();
            } else {
                this.loadOptions.filterQuery = '';
                if (this.customerIdValue && this.customerIdValue !== '' && this.customerIdValue !== null) {
                    this.search();
                } else {
                    this.receiptsCertificates = [];
                }
            }
        }, 600);
        this.debounce();
    }

    delete() {
    }

    getReferenceTypeAndCustomerId(referenceType: CodeReferenceType, customerId: string) {
        this.referenceTypeValue = referenceType;
        this.customerIdValue = customerId;
        if (this.keyword || this.customerIdValue) {
            this.search();
        }
    }

    setData(value: ReceiptCertificateResult[]) {
        this.receiptsCertificates = value;
    }

    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    // Cập nhật column
    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.getFieldSelectFromResult();

        if (!this.readOnly) {
            this.search();
        }
    }

    private search() {
        this.openLoading(true);
        this.orderService.searchForReceipt(this.loadOptions, '', this.customerIdValue,
            this.referenceTypeValue === CodeReferenceType.OrderReturned ? OrderType.Return : OrderType.Exchange)
            .finally(() => this.openLoading(false))
            .then((result: LoadResult) => {
                this.receiptsCertificates = result.data;

                if (!this.customerIdValue && this.keyword && this.receiptsCertificates
                    && this.receiptsCertificates.length > 0) {
                    const receiptCertificateInfo = this.receiptsCertificates[0];
                    this.$emit('setCustomerInfo', {
                        customerId: receiptCertificateInfo.customerId,
                        customerName: receiptCertificateInfo.customerName,
                        phone: receiptCertificateInfo.phone
                    })
                }

                this.commitValue(this.receiptsCertificates, true);
            })
    }

    private getAutomaticRemainPrice() {
        if (this.isAutomatic && this.amountValue > 0) {
            let amountRemain = this.amountValue;
            each(this.receiptsCertificates, (item: ReceiptCertificateResult) => {
                if (amountRemain >= item.remainPrice) {
                    item.cashPrice = item.remainPrice;
                    amountRemain = amountRemain - item.remainPrice;
                } else {
                    item.cashPrice = amountRemain;
                    amountRemain = 0;
                }
            });

            this.commitValue(this.receiptsCertificates, false);
            if (this.gridApi) {
                this.gridApi.refreshCells();
            }
        }
    }

    private commitValue(data: ReceiptCertificateResult[], isAutomatic: boolean = true) {
        if (isAutomatic) {
            each(data, (item: ReceiptCertificateResult) => {
                item.cashPrice = item.remainPrice;
            })
        }

        this.amountValue = sumBy(data, (it: ReceiptCertificateResult) => {
            return it.cashPrice;
        });

        this.$emit('getValue', {data: data, amount: this.amountValue});
    }

    private getFieldSelectFromResult() {
        const columnSelect = filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
            return item.isSelected;
        });

        const fieldSelect = map(columnSelect,
            (item: SettingColumnSelect) => {
                return item.field
            });

        this.loadOptions.select = this.selectFieldRequired.concat(fieldSelect).filter((idx: string) => {
            return idx !== 'actions' && idx !== 'selection';
        });

        if (!fieldSelect || fieldSelect.length < 5) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    private getColumnDefaultApi() {
        return [
            // {
            //     headerName: '',
            //     field: 'selection',
            //     filter: false,
            //     checkboxSelection: true,
            //     headerCheckboxSelectionFilteredOnly: false,
            //     headerCheckboxSelection: true,
            //     width: 50,
            //     pinned: 'left',
            //     suppressSizeToFit: true,
            //     cellClass: 'text-xs'
            // },
            {
                headerName: "Mã đơn hàng",
                field: "code",
                width: 150,
                pinned: 'left',
                suppressSizeToFit: true,
                cellRendererFramework: CellRendererLink,
            },
            {
                headerName: "Tên khách hàng",
                field: "customerName",
                width: 150,
                pinned: 'left',
                suppressSizeToFit: true,
                cellRendererFramework: CustomerCellComponent
            },
            {
                headerName: "Ngày đặt hàng",
                field: "orderDate",
                width: 150,
                cellRenderer: (data: any) => {
                    if (data.value) {
                        return `<i class="text-sm">` + moment(data.value).format('DD-MM-YYYY HH:mm') + `</i>`
                    } else {
                        return '';
                    }
                }
            },
            {
                headerName: "Giá trị đơn hàng",
                field: "totalPrice",
                width: 130,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                cellStyle: {textAlign: "right"}
            },
            {
                headerName: "Đã thu trước",
                field: "paidPrice",
                width: 120,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                cellStyle: {textAlign: "right"}
            },
            {
                headerName: "Còn cần thu",
                field: "remainPrice",
                width: 120,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                cellStyle: {textAlign: "right"}
            },
            {
                headerName: "Tiền thu",
                field: "cashPrice",
                width: 120,
                cellEditorFramework: NumbericCellEditor,
                cellRenderer: (data: any) => {
                    return `<a class="underline cursor-pointer">` + formatNumber(data.value) + `</a>`
                },
                editable: !this.readOnly,
                cellStyle: {textAlign: "right"},
                onCellValueChanged: (params: any) => {
                    if (params.newValue !== params.oldValue) {
                        this.isAutomatic = false;
                        if (params.newValue > params.data.remainPrice) {
                            this.$vs.notify({
                                color: 'danger',
                                title: 'Thông báo',
                                text: 'Số tiền thu không được lớn hơn số tiền cần thu'
                            })

                            params.data.cashPrice = params.oldValue;
                            params.api.refreshCells();
                        }
                        this.commitValue(this.receiptsCertificates, false);
                    }
                }
            },
        ];
    }

    private getDefaultColumnSetting() {
        return [
            // new SettingColumnSelect('Chọn', 'selection', true),
            new SettingColumnSelect('Mã đơn hàng', 'code', true),
            new SettingColumnSelect('Tên khách hàng', 'customerName', true),
            new SettingColumnSelect('Ngày đặt hàng', 'orderDate', true),
            new SettingColumnSelect('Giá trị đơn hàng', 'totalPrice', true),
            new SettingColumnSelect('Đã thu trước', 'paidPrice', true),
            new SettingColumnSelect('Còn cần thu', 'remainPrice', true),
            new SettingColumnSelect('Tiền thu', 'cashPrice', true),
        ]
    }

    private renderFilterQuery() {
        const queryParams = this.common.renderQuery('code', '=', this.keyword.trim().toUpperCase());
        this.loadOptions.filterQuery = JSON.stringify(queryParams)
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }
}
