









import {Component, Vue, Watch, Ref} from 'vue-property-decorator';

@Component({
})
export default class NumbericCellEditor extends Vue {
    @Ref('valueRef') valueRef !: any;

    params: any = null;
    value: any = null;
    type: string = "";

    created(){
        this.value = this.params.value;
        this.type = this.params.type ? this.params.type : 'input';
    }

    getValue(): any {
        return this.value;
    }

    afterGuiAttached() {
        this.valueRef.focus();
        this.valueRef.select();
    }

    isCharNumeric(charStr: any) {
        return !!/\d/.test(charStr) || charStr === 'Backspace';
    }

    onKeyPress(event: any){
        if (!this.isKeyPressedNumeric(event)) {
            this.valueRef.focus();

            if (event.preventDefault) event.preventDefault();
        }
    }

    isKeyPressedNumeric(event: any) {
        var charCode = this.getCharCodeFromEvent(event);
        var charStr = String.fromCharCode(charCode);

        if(charStr === ".")
        {
            return true;
        }

        return this.isCharNumeric(charStr);
    }

    getCharCodeFromEvent(event: any) {
        event = event || window.event;
        return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
}
