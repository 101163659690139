







































































import {Ref, Vue, Prop, Component, Watch, Emit} from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {AgGridVue} from 'ag-grid-vue';
import vSelect from 'vue-select'

// import _ from "lodash";
import _each from "lodash/each";
import _find from "lodash/find";
import _filter from "lodash/filter";

import draggable from 'vuedraggable'
import {PaymentMethods, PaymentMethod} from "@/constants/Order";
import {formatNumber} from "@/common";
import {Payment} from "@/models/order/Payment";

import DropDownCellEditor from "@/views/order/order-edit/cell-editor/DropDownCellEditor.vue";
import NumbericCellEditor from "@/views/order/order-edit/cell-editor/NumbericCellEditor.vue";
import CellRendererActions from '@/views/order/order-edit/cell-renderer/CellRendererActions.vue';
import CustomPinnedRowRenderer from "@/views/order/order-list/CustomPinnedRowRenderer.vue";
import {genarateId} from "@/common";

@Component({
    components: {
        draggable,
        VuePerfectScrollbar,
        AgGridVue,
        vSelect
    }
})
export default class OrderPayment extends Vue {
    @Prop({required: true}) money !: number;
    @Prop({required: true}) paidMoney !: number;
    @Prop({required: true}) data!: Array<Payment>;

    popupActive: boolean = false;
    settings: any = {
        maxScrollbarLength: 60,
        wheelSpeed: 0.70,
    };

    gridColumnApi: any = null;
    gridApi: any = null;
    gridOptions: any = null;
    columnDefs: any = null;
    defaultColDef: any = null;
    rowData: Array<Payment> = new Array<Payment>();
    paymentMethodsSelected: Array<number> = new Array<number>();
    paymentMethods: any = PaymentMethods;
    pinnedTopRowData: any = [];
    // paidMoney: number = 0;

    cellRenderComponents: any = {
        CellRendererActions
    };

    frameworkComponents: any = {
        CustomPinnedRowRenderer
    };

    constructor() {
        super();
    }

    get paymentMethodList() {
        return this.data ? this.data : new Array<Payment>();
    }

    set paymentMethodList(items: Array<Payment>) {
        this.data = items;
    }

    beforeMount() {
        this.gridOptions = {};
        this.columnDefs = [
            {
                headerName: "Hình thức thanh toán",
                field: "paymentMethod",
                width: 150,
                cellEditorFramework: DropDownCellEditor,
                cellEditorParams: {
                    options: this.paymentMethods
                },
                cellRenderer: (params: any) => {
                    let value = params.value;

                    if (typeof value === "string") {
                        return value;
                    } else {
                        let paymentMethod = _find(this.paymentMethods, (item) => {
                            return item.id === params.value;
                        });

                        let name = paymentMethod ? paymentMethod.name : '';
                        params.data.name = name;

                        return name;
                    }
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                editable: function (params: any) {
                    return !params.node.isRowPinned();
                },
                // valueSetter: function (params: any) {
                //     params.data.paymentMethod = params.newValue;

                //     return true;
                // }
            },
            {
                headerName: "Số tiền",
                field: "money",
                width: 110,
                cellEditorFramework: NumbericCellEditor,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                editable: function (params: any) {
                    return !params.node.isRowPinned();
                }
            },
            {
                headerName: "Ngân hàng",
                field: "bankId",
                width: 120,
                editable: (params: any) => {
                    return !!(params.data.paymentMethod === PaymentMethod.Transfer && !params.node.isRowPinned());
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            },
            {
                headerName: "Mã tham chiếu",
                field: "reference",
                width: 140,
                editable: (params: any) => {
                    return !!(params.data.paymentMethod === PaymentMethod.Transfer && !params.node.isRowPinned());
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            },
            {
                headerName: '',
                field: 'actions',
                width: 45,
                cellRendererFramework: CellRendererActions,
                cellRendererParams: {
                    onClick: this.delete,
                    className: 'btn-delete'
                },
                editable: false,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            }
        ];

        this.defaultColDef = {};
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
    }

    openPopup() {
        this.popupActive = true;

        if (this.paymentMethodList.length === 1) {
            _each(this.paymentMethodList, (item: Payment) => {
                item.money = this.paidMoney;
            });
        }

        if (this.paidMoney > 0 && this.paymentMethodList.length === 0) {
            let id = genarateId();
            let method = PaymentMethods.find(m => m.id === PaymentMethod.Cash);

            if (method) {
                let data = new Payment(id, PaymentMethod.Cash, method.name);
                data.money = this.paidMoney;
                this.paymentMethodList.push(data);
            }
        }

        this.rowData = this.paymentMethodList;
    }

    onChosenPaymentMethod() {
        if (this.paymentMethodsSelected.length === 0) {
            this.$vs.notify({
                title: '',
                text: "Vui lòng chọn hình thức thanh toán!",
                color: "danger"
            });
        }

        let paymentMethodsSelected = _filter(this.paymentMethods, (paymentMethod: any) => {
            return this.paymentMethodsSelected.indexOf(paymentMethod.id) > -1;
        });

        _each(paymentMethodsSelected, (item: any) => {
            let id = genarateId();

            this.rowData.unshift(new Payment(id, item.id, item.name));
        });

        this.paymentMethodsSelected = [];
    }

    formatNumber(value: any) {
        return formatNumber(value);
    }

    delete(data: Payment) {
        this.rowData = _filter(this.rowData, (item: Payment) => {
            return item.id !== data.id;
        });
    }

    @Watch('rowData', {deep: true, immediate: true})
    onRowDataChanged(methods: Array<Payment>, oldMethods: Array<Payment>) {
        let sumPaidMoney = 0;

        _each(methods, (method: Payment) => {
            sumPaidMoney = sumPaidMoney + parseFloat((method.money ? method.money : 0).toString());
        });

        // this.paidMoney = sumPaidMoney;
        this.pinnedTopRowData = [{money: sumPaidMoney}];
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    onSaveSuccessed(value: number, items: Array<Payment>) {
        this.$emit('saveSuccessed', value, items)
    }

    save() {
        // let data = _filter(this.rowData, (item: Payment) => {
        //     return item.money && item.money > 0;
        // }) as Array<Payment>;
        let data = this.rowData;

        let checkMoney = _filter(data, (item: Payment) => {
            return !item.money || item.money === 0;
        });

        if (checkMoney.length > 0) {
            this.$vs.notify({
                title: '',
                text: "Vui lòng nhập số tiền!",
                color: "danger"
            });

            return false;
        }

        let checkBankId = _filter(data, (item: Payment) => {
            return item.paymentMethod === PaymentMethod.Transfer && !item.bankId;
        });

        if (checkBankId.length > 0) {
            this.$vs.notify({
                title: '',
                text: "Vui lòng nhập tên ngân hàng đối với hình thức thanh toán chuyển khoản!",
                color: "danger"
            });

            return false;
        }

        let checkReference = _filter(data, (item: Payment) => {
            return item.paymentMethod === PaymentMethod.Transfer && !item.reference;
        });

        if (checkReference.length > 0) {
            this.$vs.notify({
                title: '',
                text: "Vui lòng nhập mã tham chiều đối với hình thức thanh toán chuyển khoản!",
                color: "danger"
            });

            return false;
        }

        let sumPaidMoney = 0;

        _each(data, (item: Payment) => {
            this.$delete(item, 'createTime');
            // this.$delete(item, 'creatorFullName');
            // _omit(item, ['createTime', 'creatorFullName']);
            sumPaidMoney = sumPaidMoney + parseFloat((item.money ? item.money : 0).toString());
        });

        this.paymentMethodList = data;
        this.onSaveSuccessed(sumPaidMoney, data);
        this.popupActive = false;
    }

    close() {
        this.popupActive = false;
    }

    // listen emit close of popup.
    onPopupClose(value: boolean) {

    }
}
