import {CodeReferenceType, GroupPeopleType, ReceiptStatus, ReceiptType} from "@/constants/receipt";
import {ReceiptPayment} from "@/models/cash-book/receipt/Receipt";
import {ReceiptCertificateResult} from "@/models/cash-book/receipt/ReceiptCertificateResult";

export class ReceiptDetail {
    id: string;
    createTime: Date;
    creatorId: string;
    creatorFullName: string;
    code: string;
    codeReference: string;
    objectType: GroupPeopleType;
    receiptFullName: string;
    receiptPhoneNumber: string;
    amount: number;
    receiptGroupId: string;
    receiptGroupName: string;
    paymentMethodString: string;
    isAccounting: boolean;
    dateRecording?: Date;
    note: string;
    branchId: string;
    branchName: string;
    status: ReceiptStatus;
    type: ReceiptType;
    customerId: string;
    customerName: string;
    isCalculateAutomatic: boolean;
    isAutomation: boolean;
    referenceType: CodeReferenceType;
    receiptPayments: ReceiptPayment[];
    receiptCertificates: ReceiptCertificateResult[];
}