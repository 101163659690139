






import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class CellRendererActions extends Vue {
    params: any = null;
    className: string = '';

    created(){
        this.className = this.params.className;
    }

    onClick() {
        if (this.params.onClick) {
            this.params.onClick(this.params.data);
        }
    }
}
