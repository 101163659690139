






























import {Vue, Prop, Component, Ref, Watch} from "vue-property-decorator";
import {ReceiptType} from "@/constants/receipt";
import {ReceiptService} from "@/views/cash-book/receipt/receipt.service";
import {ReceiptUser} from "@/models/cash-book/receipt/ReceiptUser";
import vSelect from 'vue-select';
import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
import VueSuggest from 'vue-simple-suggest';

@Component({
    components: {
        'v-select': vSelect,
        VueSuggest,
    }
})
export default class ReceiptUserSuggestion extends Vue {
    @Ref('receiverSuggest') receiverSuggest!: any;
    @Prop({default: () => ''}) placeholder: string;
    @Prop({default: () => false}) readonly: boolean;
    @Prop({default: () => ''}) value: string;

    receiptService = new ReceiptService();
    model: string = '';

    constructor() {
        super();
    }

    @Watch('value')
    getModel(value: string) {
        this.model = value;
    }

    selectValue(value: ReceiptUser) {
        if (value) {
            this.$emit('getValue', value);
        } else {
            this.$emit('getValue', null);
        }
    }

    async onSearchReceivers(inputValue: string) {
        this.$emit('getModel', inputValue);
        const result = await this.receiptService.suggestionUser(inputValue);
        return result.items;
    }

    boldenSuggestion(scope: any) {
        if (!scope)
            return scope;

        const {suggestion, query} = scope;
        let result = this.receiverSuggest.displayProperty(suggestion);

        if (!query)
            return result;

        const texts = query.split(/[\s-_/\\|.]/gm).filter((t: any) => !!t) || [''];
        return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'), '$1<b>$2</b>$3');
    }
}
