



























































































































































































































































































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {CodeReferenceType, OptionPaymentMethod, ReceiptStatus, ReceiptType} from "@/constants/receipt";
import {Receipt, ReceiptCertificate, ReceiptPayment} from "@/models/cash-book/receipt/Receipt";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Vietnamese as VietnameseLocale} from 'flatpickr/dist/l10n/vn.js';
import ReceiptUserSuggestion from "@/views/cash-book/receipt/component/receipt-user.suggestion.vue";
import ReceiptGroupSuggestion from "@/views/cash-book/receipt/component/receipt-group-suggestion.vue";
import {Money} from 'v-money';
import {ReceiptUser} from "@/models/cash-book/receipt/ReceiptUser";
import {SuggestionModel} from "@/components/suggestion/base-suggestion/SuggestionModel";
import {ReceiptGroupSuggestionModel} from "@/models/cash-book/receipt/ReceiptGroupSuggestionModel";
import {CustomerSuggestionModel} from "@/components/suggestion/customer-suggestion/CustomerSuggestionModel";
import {ReceiptService} from "@/views/cash-book/receipt/receipt.service";
import {ActionResult} from "@/models/ActionResult";
import moment from "moment";
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import {PaymentMethod, PaymentMethodOptions, PaymentMethods} from "@/constants/Order";
import {SearchResult} from "@/models/SearchResult";
import ReceiptCertificateComponent from "@/views/cash-book/receipt/component/receipt-certificate.vue";
import {ReceiptCertificateResult} from "@/models/cash-book/receipt/ReceiptCertificateResult";
import {formatNumber} from '@/common';
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import i18n from "@/i18n";
import OrderPayment from "@/views/order/order-edit/OrderPayment.vue";
import {Payment} from "@/models/order/Payment";
import _each from 'lodash/each';
import vSelect from 'vue-select';
import {ReceiptDetail} from "@/models/cash-book/receipt/ReceiptDetail";
import {Getter} from "vuex-class";

extend("required", {
    ...required,
    message: (_: string, values: any) => i18n.t("validations.required", [_])
} as any);

@Component({
    components: {
        'v-select': vSelect,
        flatPickr,
        Money,
        ReceiptUserSuggestion,
        ReceiptGroupSuggestion,
        ReceiptCertificateComponent,
        ValidationObserver,
        ValidationProvider,
        OrderPayment,
        CustomerSuggestion: () => import(/* webpackChunkName: "CustomerSuggestion" */ "@/components/suggestion/customer-suggestion/CustomerSuggestion.vue"),
    }
})

export default class ReceiptForm extends Vue {
    @Getter("oidcUser") oidcUser!: any;
    @Ref('moneyAmount') moneyAmount: any;
    @Ref('orderPaymentRef') orderPaymentRef: any;
    @Ref('customerSuggestion') customerSuggestion!: any;
    @Ref('datePicker') datePicker!: any;
    @Ref('receiptCertificate') receiptCertificate!: any;
    @Prop({default: () => ReceiptType.Payment}) type: ReceiptType;

    id: string;
    receiptTitle: string = 'Thêm mới phiếu chi';
    isUpdate: boolean = false;
    readOnly = false;
    receipt: Receipt = new Receipt();
    receiptType = ReceiptType;
    configDateTimePicker: any = {
        dateFormat: 'd-m-Y H:i',
        locale: VietnameseLocale,
        enableTime: true,
    };
    wasSidebarOpen: any = null;
    moneyOption: any = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };
    paymentMethods: any = PaymentMethods;
    listReceiptGroupSuggestion: any[];
    listCustomerSelected: any[] = [];
    listReceiptGroupSelected: any[] = [];
    paymentMethodsSelected: any = {};
    referenceType: CodeReferenceType | null = CodeReferenceType.Contract;
    codeReferenceType = CodeReferenceType;
    receiptService = new ReceiptService();
    receiptDetail: ReceiptDetail = new ReceiptDetail();
    typeValue: ReceiptType = ReceiptType.Payment;
    saving: boolean = false;

    constructor() {
        super();
        this.typeValue = this.type;
        this.receiptTitle = this.typeValue === ReceiptType.Payment ? 'Phiếu chi' : 'Phiếu thu';
    }

    async beforeMount() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.isUpdate = true;
        }
    }

    mounted() {
        this.GetListReceiptGroup();
        this.paymentMethodsSelected = {
            id: PaymentMethod.Cash,
            name: new PaymentMethodOptions(PaymentMethod.Cash).name
        };
        // this.wasSidebarOpen = this.$store.state.reduceButton;
        // this.$store.commit('TOGGLE_REDUCE_BUTTON', false);

        if (this.id) {
            try {
                this.receiptService.getDetail(this.id).then((result: ActionResult<ReceiptDetail>) => {
                    if (result && result.code > 0) {
                        const data = result.data;

                        if (data) {
                            this.receiptDetail = data;
                            this.receipt.code = data.code;
                            this.receipt.codeReference = data.codeReference;
                            this.receipt.receiptFullName = data.receiptFullName;
                            this.receipt.customerName = data.customerName;
                            this.receipt.receiptPhoneNumber = data.receiptPhoneNumber;
                            this.receipt.receiptGroupId = data.receiptGroupId;
                            this.receipt.receiptGroupName = data.receiptGroupName;
                            this.referenceType = data.referenceType;
                            this.receipt.amount = data.amount;
                            this.receipt.customerId = data.customerId;
                            this.receipt.dateRecording = moment(data.dateRecording).format('DD/MM/YYYY HH:mm');
                            this.receipt.note = data.note;
                            this.receipt.receiptPayments = data.receiptPayments;
                            this.receipt.isCalculateAutomatic = data.isCalculateAutomatic;
                            this.isUpdate = true;
                            this.typeValue = data.type;

                            // Phương thức thanh toán
                            this.paymentMethodsSelected = map(this.receipt.receiptPayments, (item: ReceiptPayment) => {
                                return {
                                    id: item.paymentMethod,
                                    name: new OptionPaymentMethod(item.paymentMethod).name
                                }
                            });

                            // loại phiếu chi
                            this.listReceiptGroupSelected = [{
                                id: data.receiptGroupId,
                                text: data.receiptGroupName
                            }];

                            // Khách hàng
                            this.listCustomerSelected = [{
                                id: data.customerId,
                                text: data.customerName
                            }];

                            // Readonly
                            this.readOnly = moment(data.dateRecording).format('DD-MM-YYYY') !== moment().format('DD-MM-YYYY')
                                || data.status === ReceiptStatus.Cancel
                                || data.creatorId !== this.currentUser.userId || data.isAutomation;
                            console.log(data.creatorId, this.currentUser.userId);
                            this.isUpdate = true;
                        }
                    }
                })
            } catch (e) {
                this.receipt = new Receipt();
                this.$vs.notify({
                    title: '',
                    text: e.message,
                    color: 'danger'
                });
            }
        }
    }

    @Watch('referenceType')
    changeReferenceType(value: CodeReferenceType) {
        if (value === CodeReferenceType.Order || value === CodeReferenceType.OrderReturned) {
            setTimeout(() => {
                if (this.receiptCertificate) {
                    if (!this.isUpdate) {
                        this.receiptCertificate.getReferenceTypeAndCustomerId(value, this.receipt.customerId);
                    } else {
                        this.receiptCertificate.setData(this.receiptDetail.receiptCertificates);
                    }
                }
            }, 50);
        }
    }

    @Watch('receipt.amount')
    getPaymentMethodsSelectedFromAmount(value: number) {
        this.receipt.receiptPayments = [{
            id: this.paymentMethodsSelected.id,
            name: new OptionPaymentMethod(this.paymentMethodsSelected.id).name,
            paymentMethod: this.paymentMethodsSelected.id,
            referenceCode: '',
            amount: value,
            money: value
        }];
    }

    @Watch('paymentMethodsSelected')
    getPaymentMethodsSelected(value: any) {
        if (value) {
            this.receipt.receiptPayments = [{
                id: value.id,
                name: new OptionPaymentMethod(value.id).name,
                paymentMethod: value.id,
                referenceCode: '',
                amount: this.receipt.amount,
                money: this.receipt.amount
            }];
        } else {
            this.receipt.receiptPayments = [];
        }
    }

    @Watch('type')
    getType(value: number) {
        this.typeValue = value;
    }

    get currentUser() {
        return this.oidcUser;
    }

    async onCustomerSelected(customers: SuggestionModel<CustomerSuggestionModel>[]) {
        let customer = customers[0];
        this.listCustomerSelected = customers;
        if (customer) {
            this.receipt.customerId = customer.id;
            if (customer.data) {
                if (customer.data.phoneNumber) {
                    this.receipt.receiptPhoneNumber = customer.data.phoneNumber;
                }
                this.receipt.receiptFullName = customer.data.name;
            }
        }
    }

    onCustomerSelectedRemove(data: any) {
    }

    getUser(value: ReceiptUser) {
        if (value) {
            this.receipt.receiptFullName = value.fullName;
            this.receipt.receiptPhoneNumber = value.phoneNumber;
        }
    }

    getReceiptUserName(value: string) {
        this.receipt.receiptFullName = value;
    }

    selectDate() {
        this.datePicker.fp.open();
    }

    selectReceiptGroup(values: SuggestionModel<ReceiptGroupSuggestionModel>[]) {
        this.listReceiptGroupSelected = values;
        if (values && values.length > 0) {
            this.receipt.receiptGroupId = values[0].id;
            if (values[0].data) {
                this.referenceType = values[0].data.referenceType;
            }
        } else {
            this.receipt.receiptGroupId = '';
            this.referenceType = null;
        }
    }

    save() {
        //const isValid = this.$refs.observer.validate();
        if (!this.receipt.receiptGroupId) {
            this.$vs.notify({
                title: 'Thông báo',
                text: 'Vui lòng nhập loại phiếu.',
                color: 'warning'
            });
            return;
        }

        if (!this.receipt.receiptFullName) {
            this.$vs.notify({
                title: 'Thông báo',
                text: `Vui lòng nhập tên người  ${this.type === ReceiptType.Payment ? 'nộp' : 'gửi'} .`,
                color: 'warning'
            });
            return;
        }

        if (!this.receipt.receiptPhoneNumber) {
            this.$vs.notify({
                title: 'Thông báo',
                text: `Vui lòng nhập số điện thoại người  ${this.type === ReceiptType.Payment ? 'nộp' : 'gửi'} .`,
                color: 'warning'
            });
            return;
        }

        if (this.receipt.amount <= 0) {
            this.$vs.notify({
                title: 'Thông báo',
                text: `Số tiền phải lớn hơn 0.`,
                color: 'warning'
            });

            setTimeout(() => {
                this.moneyAmount.$el.focus();
            }, 100);
            return;
        }

        if (this.paymentMethodsSelected.length <= 0) {
            this.$vs.notify({
                title: 'Thông báo',
                text: `Bạn phải chọn ít nhất một phương thức thanh toán.`,
                color: 'warning'
            });
            return;
        }

        if ((this.referenceType === CodeReferenceType.OrderReturned || this.referenceType === CodeReferenceType.Order)
            && (!this.receipt.receiptCertificates || this.receipt.receiptCertificates.length === 0)) {
            this.$vs.notify({
                title: 'Thông báo',
                text: `Chưa có đơn hàng nào cần thanh toán.`,
                color: 'warning'
            });
            return;
        }

        const amountReceiptCertificate = sumBy(this.receipt.receiptCertificates, (it: ReceiptCertificate) => {
            return it.amount;
        });

        // Check tổng số đơn hàng lớn hơn
        if ((this.receipt.amount > amountReceiptCertificate)
            && (this.referenceType === CodeReferenceType.Order || this.referenceType === CodeReferenceType.OrderReturned)) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Thanh toán',
                acceptText: this.$t('Accept'),
                cancelText: this.$t('Cancel'),
                text: `Số tiền thanh toán lớn hớn  ${formatNumber(this.receipt.amount - amountReceiptCertificate)} đ số tiền trong đơn hàng phải trả
                 . Bạn có muốn thanh toán số tiền bằng với giá trị đơn hàng.`,
                accept: () => {
                    this.receipt.amount = amountReceiptCertificate;
                    this.save();
                },
                cancel: () => {
                    setTimeout(() => {
                        this.moneyAmount.$el.focus();
                    }, 100);
                }
            })
            return;
        }

        if ((this.receipt.amount < amountReceiptCertificate)
            && (this.referenceType === CodeReferenceType.Order || this.referenceType === CodeReferenceType.OrderReturned)) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Thanh toán',
                acceptText: this.$t('Accept'),
                cancelText: this.$t('Cancel'),
                text: `Số tiền thanh toán nhỏ hớn  ${formatNumber(amountReceiptCertificate - this.receipt.amount)} đ số tiền trong đơn hàng phải trả
                 . Bạn có muốn thanh toán số tiền bằng với giá trị đơn hàng.`,
                accept: () => {
                    this.receipt.amount = amountReceiptCertificate;
                    this.save();
                },
                cancel: () => {
                    setTimeout(() => {
                        this.moneyAmount.$el.focus();
                    }, 100);
                }
            })
            return;
        }

        this.receipt.dateRecording = moment(this.receipt.dateRecording, 'DD-MM-YYYY').format();
        this.receipt.receiptPayments = [new ReceiptPayment(PaymentMethod.COD, '', this.receipt.amount)];
        this.receipt.type = this.type;

        try {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: "#btnSaveReceipt",
                scale: 0.45
            })

            this.saving = true;
            if (this.id) {
                this.receiptService.update(this.id, this.receipt).then((result: ActionResult<string>) => {
                    this.$vs.notify({
                        title: "Thông báo", text: result.message, color: (result.code > 0 ? 'primary' : 'danger'),
                        iconPack: 'feather', icon: 'icon-info'
                    });

                    this.saving = false;
                    this.$vs.loading.close('#btnSaveReceipt > .con-vs-loading')
                })
            } else {
                this.receiptService.insert(this.receipt).then((result: ActionResult<string>) => {
                    this.$vs.notify({
                        title: "Thông báo", text: result.message, color: (result.code > 0 ? 'primary' : 'danger'),
                        iconPack: 'feather', icon: 'icon-info'
                    });

                    this.saving = false;
                    this.$vs.loading.close('#btnSaveReceipt > .con-vs-loading')
                    if (result.code > 0) {
                        this.resetForm();
                    }
                })
            }
        } catch (e) {
            this.$vs.notify({
                title: '',
                text: e.message,
                color: 'danger'
            });

            this.saving = false;
        }
    }

    deleteReceipt() {
        if (this.id && !this.readOnly) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('ConfirmDelete'),
                acceptText: this.$t('Accept'),
                cancelText: this.$t('Cancel'),
                text: this.$t('ConfirmDeleteText', {data: this.receiptDetail.code}),
                accept: this.cancel
            })
        } else {
            this.$router.push({name: this.typeValue === ReceiptType.Payment ? 'paymentVoucherList' : 'receiptVoucherList'});
        }
    }

    cancel() {
        this.receiptService.cancel(this.receiptDetail.id).then((result: ActionResult) => {
            this.$vs.notify({
                color: result.code <= 0 ? 'danger' : 'success',
                title: 'Thông báo',
                text: result.message
            })
        }).catch((err: any) => {
            this.$vs.notify({
                color: 'danger',
                title: 'Thông báo',
                text: err.message
            })
        })
    }

    onChosenPaymentMethod(values: any[]) {
        this.orderPaymentRef.openPopup();
    }

    getReceiptCertificate(value: any) {
        if (value) {
            if (this.receipt.amount === 0) {
                this.receipt.amount = value.amount;
            }

            this.receipt.receiptCertificates = map(value.data, (item: ReceiptCertificateResult) => {
                return {
                    certificateId: item.id,
                    certificateCode: item.code,
                    referenceType: this.referenceType,
                    amount: item.cashPrice
                }
            });
        }
    }

    setCustomerInfo(value: any) {
        if (value) {
            this.receipt.customerId = value.customerId;
            this.receipt.receiptFullName = value.customerName;
            this.receipt.receiptPhoneNumber = value.phone;
            this.listCustomerSelected = [{
                id: value.customerId,
                text: value.customerName,
                isSelected: true,
                description: '',
                isActivated: true,
                data: undefined
            }]
        }
    }

    formatNumber(value: number) {
        return formatNumber(value);
    }

    onPaymentMethodSaveSuccessed(value: number, items: Array<Payment>) {
        _each(items, (item: Payment) => {
            item.money = parseFloat((item.money ? item.money : 0).toString());
        });

        this.receipt.receiptPayments = map(items, (item: Payment) => {
            return {
                id: item.paymentMethod,
                name: new OptionPaymentMethod(item.paymentMethod).name,
                paymentMethod: item.paymentMethod,
                referenceCode: item.bankId,
                amount: item.money,
                money: item.money
            }
        });
        this.receipt.amount = value;
    }

    private GetListReceiptGroup() {
        this.receiptService.suggestionGroup('', this.type).then((result: SearchResult<ReceiptGroupSuggestionModel>) => {
            this.listReceiptGroupSuggestion = result.items.map((receiptGroupSuggestion: ReceiptGroupSuggestionModel) => {
                return new SuggestionModel(
                    receiptGroupSuggestion.id,
                    receiptGroupSuggestion.name,
                    undefined,
                    undefined,
                    this.checkIsSelected(receiptGroupSuggestion.id),
                    receiptGroupSuggestion
                )
            });

            if (this.listReceiptGroupSuggestion && this.listReceiptGroupSuggestion.length > 0) {
                const receiptGroupFirst = this.listReceiptGroupSuggestion[0];
                this.listReceiptGroupSelected = [receiptGroupFirst];
                this.receipt.receiptGroupId = receiptGroupFirst.id;
                if (receiptGroupFirst && receiptGroupFirst.data) {
                    this.referenceType = receiptGroupFirst.data.referenceType;
                }
            }
        });
    }

    private checkIsSelected(id: string): boolean {
        const index = this.listReceiptGroupSelected.findIndex(
            (item: SuggestionModel) => {
                return item.id === id;
            }
        );
        return index !== -1;
    }

    private resetForm() {
        this.listCustomerSelected = [];
        const receiptGroupId = this.receipt.receiptGroupId;
        this.receipt = new Receipt();
        this.receipt.type = this.type;
        this.referenceType = this.referenceType;
        this.receipt.receiptGroupId = receiptGroupId;
        this.receipt.customerId = '';
        if (this.referenceType === CodeReferenceType.Order || this.referenceType === CodeReferenceType.OrderReturned) {
            this.receiptCertificate.setData([]);
        }
    }
}
