








import {Component, Vue} from 'vue-property-decorator';
import _find from 'lodash/find';

@Component({})
export default class DropDownCellEditor extends Vue {
    params: any = null;
    options: any = null;
    value: any = null;

    created(){
        this.options = this.params.options;
        let value = this.params.value;

        if (typeof value === "string") {
            let option = _find(this.options, (item: any) => {
                return item.name === this.params.value;
            });

            this.value = option ? option.id : 0;
        }
        else{
            this.value = value;
        }
    }

    getValue(): any {
        return this.value;
    }
}
