export enum SuggestionType {
    popup = 'popup',
    input = 'input'
}

export class SuggestionModel<T = any> {
    id: string;
    text: string;
    description: string;
    avatar?: string;
    isSelected: boolean;
    isActivated: boolean;
    data: T | undefined;
    erpCode? : string;

    constructor(id?: string, text?: string, description?: string, avatar?: string, isSelected: boolean = false, data?: T, erpCode?: string) {
        this.id = id ? id : '';
        this.text = text ? text : '';
        this.description = description ? description : '';
        this.avatar = avatar;
        this.isSelected = isSelected;
        this.isActivated = false;
        this.data = data;
        this.erpCode = erpCode;
    }
}